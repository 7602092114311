@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-parallax {
  $el: &;
  height: 50vh;
  overflow: hidden;
  background: $color-transparent;
  margin: spacing(xl) 0;
  @media (min-width: breakpoint(lg)) {
    height: 75vh;
  }
  &__img {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: breakpoint(lg)) {
      background-attachment: fixed;
    }
  }
}