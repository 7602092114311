@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-block-tti-dx {
  $el: &;
  position: relative;
  padding: spacing(l) spacing(m);
  margin: spacing(l) auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: breakpoint(lg)) {
    padding: spacing(xl) spacing(l);
    flex-direction: row;
    align-items: center;
  }
  @media (min-width: breakpoint(xxl)) {
    padding: spacing(xl) (spacing(xl) + spacing(l));
  }
  &--no-left {
    @media (min-width: breakpoint(lg)) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__info {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: spacing(xl);
    order: 2;
    @media (min-width: breakpoint(lg)) {
      order: 1;
      margin-bottom: 0;
      width: 50%;
      padding-right: spacing(xl);
    }
    &-titolo {
      position: relative;
      padding: spacing(s) spacing(m);
      margin-bottom: spacing(l) + spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: spacing(s) (spacing(l) + spacing(m));
      }
      & h2 {
        position: relative;
        @include font-48-bold();
        color: $color-text;
        z-index: 3;
        margin: 0;
      }
      &-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-color: $color-red;
        z-index: 2;
      }
      &-bg {
        position: absolute;
        top: 50%;
        left: spacing(m) + spacing(s);
        transform: translateY(-50%);
        @include font-237-bold();
        color: $color-gray-light;
        z-index: 2;
        @media (min-width: breakpoint(lg)) {
          left: spacing(l) + spacing(m) + spacing(s);
        }
      }
    }
    &-sottotitolo {
      @include font-36-light();
      color: $color-text;
      line-height: 1.5;
      margin-bottom: spacing(m);
      text-transform: uppercase;
      padding: 0 spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: 0;
        padding-left: (spacing(l) + spacing(m));
      }
    }
    &-testo {
      @include font-18-reg();
      color: $color-text;
      padding: 0 spacing(m);
      line-height: 1.5;
      & strong{
        @include font-18-bold();
        line-height: 1.5;
      }
      & p {
        margin-bottom: spacing(m);
      }
      @media (min-width: breakpoint(lg)) {
        padding: 0;
        padding-left: (spacing(l) + spacing(m));
        width: 80%;
      }
    }
    &-link {
      display: inline-block;
      margin-top: spacing(m);
      padding-left: (spacing(l) + spacing(m));;
    }
  }
  &__gallery {
    position: relative;
    order: 2;
    @media (min-width: breakpoint(xl)) {
      order: 1;
      width: 50%;
    }
    &-bg {
      position: absolute;
      top: 0;
      right: 0;
      width: 55%;
      height: 100%;
      background-color: $color-red;
      z-index: 1;
    }
    & > iframe {
      position: relative;
      z-index: 2;
      padding: spacing(xl) 0;
      width: 90%;
      margin-left: 0;
      @media (min-width: breakpoint(lg)) {
        margin-right: 4.5vw;
      }
    }
    & .swiper {
      z-index: 2;
      padding: spacing(xl) 0;
      width: 90%;
      margin-left: 0;
      margin-right: auto;
      & .swiper-pagination {
        text-align: left;
      }
    }
  }
}

