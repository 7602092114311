@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.co-modal {
  $el: &;
  & .modal-content {
    border-radius: spacing(m);
    border: none;
    background: radial-gradient(70.61% 70.61% at 48.72% 48.54%, #E1E1E1 99.99%, #105494 100%, #105494 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    color: $color-text;
    overflow: hidden;
    box-shadow: -5px 6px 5px 0px #00000040;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 425px;
      height: 325px;
      background-color: #105494;
      // transform: translate(10%, -30%) rotate(115deg);
      transform: translate(75%, 45%) rotate(115deg);
      z-index: 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 425px;
      height: 325px;
      background-color: #105494;
      transform: translate(-75%, -45%) rotate(115deg);
      z-index: 0;
    }
  }
  & .modal-header {
    position: relative;
    z-index: 1;
    border: none;
    padding: spacing(s) spacing(m);
    & .btn-close {
      background-image: url('closer-d.svg');
      background-size: spacing(l);
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 1;
    }
  }
  & .modal-body {
    position: relative;
    z-index: 1;
    padding-top: 0;
    & p {
       @include font-24-bold();
      & b {
        @include font-24-bold();
      }
    }
  }
  & .modal-footer {
    position: relative;
    z-index: 1;
    border: none;
    justify-content: center;
    & button {
      margin-left: spacing(l);
    }
  }
}