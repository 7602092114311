@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.ca-general-plus {
  $el: &;
  display: block;
  width: spacing(l);
  height: spacing(l);
  min-width: spacing(l);
  min-height: spacing(l);
  background: $color-red;
  border: 2px solid $color-red;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
  transition: all 350ms ease-in-out;
  z-index: zindex(xl);
  &::before {
    content: '+';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include font-24-reg();
    color: $color-white;
    transition: all 350ms ease-in-out;
  }
  &:hover {
    background: $color-white;
    &::before {
      color: $color-red;
    }
  }
  &--neg {
    background: $color-transparent;
    &::before {
      color: $color-red;
    }
    &:hover {
      background: $color-red;
      &::before {
        color: $color-white !important;
      }
    }
  }
}
