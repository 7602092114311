@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-menu-mobile {
  $el: &;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: $color-white;
  width: 100%;
  height: 100vh;
  z-index: zindex(xl);
  text-align: left;
  overflow: hidden;
  @media (min-width: breakpoint(xl)) {
    display: none;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    padding: spacing(xl) spacing(xl);
    padding-top: spacing(xxl) + spacing(xl);
    width: 100%;
    vertical-align: middle;
  }
  &__list-item {
    &--0 {
      position: relative;
      list-style-type: none;
      top: -10px;
      opacity: 0;
      transition: all 350ms ease-in-out;
      transition-delay: 150ms;
      margin-bottom: spacing(m);
      & > a {
        display: block;
        padding-bottom: spacing(s);
        width: 100%;
        @include font-12-bold();
        color: $color-text !important;
        transition: all 350ms;
        text-transform: uppercase;
        &.is-active {
          color: $color-red;
        }
      }
    }
    &--expanded {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        color: $color-red;
        background: $color-white;
        top: 0;
        left: auto;
        bottom: auto;
        transform: none;
        transition: all 350ms ease-in-out;
        top: 5px;
        right: auto;
        left: 25%;
        bottom: auto;
        transform: none;
        width: 0; 
        height: 0; 
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid $color-red;
        transition: all 350ms ease-in-out;
        display: none;
        @media (min-width: breakpoint(lg)) {
          display: block;
        }
      }
      & > a {
        cursor: default;
      }
    }
    &--1 {
      position: relative;
      list-style-type: none;
      top: -10px;
      opacity: 0;
      transition: all 350ms ease-in-out;
      transition-delay: 150ms;
      margin-bottom: spacing(s);
      & > a {
        position: relative;
        display: block;
        width: 100%;
        @include font-12-bold();
        color: $color-text !important;
        transition: all 350ms;
        &.is-active {
          color: $color-red;
        }
      }
      &.cm-menu-main__list-item--active-trail {
        & > a {
          color: $color-red !important;
        }
      }
    }
  }
  &__submenu {
    display: block;
    position: relative;
    padding-left: 0;
    padding-top: spacing(m);
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: all 250ms ease-in-out;
  }

}
