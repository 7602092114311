@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-block-iframe {
  $el: &;
  position: relative;
  padding: spacing(l) spacing(m);
  margin: spacing(xl) auto;
  @media (min-width: breakpoint(lg)) {
    padding: 0 spacing(xl);
  }
  @media (min-width: breakpoint(xl)) {
    padding: 0 spacing(xxl);
  }
  &__info {
    position: relative;
    width: 100%;
    text-align: center;
    &-titolo {
      position: relative;
      padding: spacing(s) spacing(m);
      margin-bottom: spacing(l) + spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: spacing(s) (spacing(l) + spacing(m));
      }
      & h2 {
        position: relative;
        @include font-48-bold();
        color: $color-text;
        z-index: 3;
        margin: 0;
      }
      &-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-color: $color-red;
        z-index: 2;
      }
      &-bg {
        position: absolute;
        top: 50%;
        left: spacing(m) + spacing(s);
        transform: translateY(-50%);
        @include font-237-bold();
        color: $color-gray-light;
        z-index: 2;
        @media (min-width: breakpoint(lg)) {
          left: spacing(l) + spacing(m) + spacing(s);
        }
      }
    }
    
  }
}