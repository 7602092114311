@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.ca-toggler {
  $el: &;
  display: block;
  position: relative;
  z-index: zindex(xxl);
  width: spacing(xl);
  height: spacing(xl);
  transition: all 850ms ease-in-out;
  cursor: pointer;
  // box-shadow: 2.02px 2.02px 8.08px 0px #00000040;
  @media (min-width: breakpoint(sm)) {
    width: 80px;
    height: 80px;
  }
  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__line {
    position: relative;
    width: spacing(l) + spacing(s);
    height: 5px;
    margin-top: spacing(xs);
    margin-bottom: spacing(xxs);
    border-radius: spacing(xs);
    background: $color-red;
    transition: all 350ms ease-in-out;
  }
  &__line:nth-child(1),
  &__line:nth-child(3) {
    width: spacing(l);
  }
  &.is-open &__line:nth-child(1) {
    width: 55%;
    margin: 0;
    transform: translate(0px, 2px) rotate(45deg);
  }
  &.is-open &__line:nth-child(2) {
    margin: 0;
    width: 0;
    height: 0;
  }
  &.is-open &__line:nth-child(3) {
    width: 55%;
    margin: 0;
    transform: translate(0px, -3px) rotate(-45deg);
  }
  @media (min-width: breakpoint(lg)) {
    &:hover {
      #{$el}__line:nth-child(1),
      #{$el}__line:nth-child(3) {
        width: spacing(l) + spacing(s);
      }
    }
  }
}
