@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-menu-main {
  $el: &;
  position: relative;
  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    padding: 0 spacing(m);
    width: 100%;
    vertical-align: middle;
    @media (min-width: breakpoint(lg)) {
      flex-direction: row;
      width: 100%;
    }
  }
  &__list-item {
    &--0 {
      position: relative;
      list-style-type: none;
      padding: 0 (spacing(m) + spacing(s));
      &::after {
        content: '';
        width: 0;
        height: 6px;
        background: $color-red;
        position: absolute;
        bottom: -24px;
        transition: all 350ms ease-in-out;
        left: 50%;
        transform: translateX(-50%);
      }
      & > a {
        display: block;
        padding-bottom: spacing(s);
        width: 100%;
        @include font-12-bold();
        color: $color-text !important;
        transition: all 350ms;
        text-transform: uppercase;
        &::after {
          content: '';
          width: 0;
          height: 6px;
          background: $color-red;
          position: absolute;
          bottom: -24px;
          transition: all 350ms ease-in-out;
          left: 50%;
          transform: translateX(-50%);
        }
        &.is-active {
          color: $color-red;
          &::after {
            width: 85%;
          }
        }
      }
      @media (min-width: breakpoint(lg)) {
        &:hover {
          &::after {
            width: 85%;
          }
        }
      }
    }
    &--expanded {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        color: $color-red;
        background: $color-white;
        top: 0;
        left: auto;
        bottom: auto;
        transform: none;
        transition: all 350ms ease-in-out;
        top: 4px;
        right: 0px;
        left: auto;
        bottom: auto;
        transform: none;
        width: 0; 
        height: 0; 
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid $color-red;
        right: spacing(s) + spacing(xs);
        margin-right: -10px;
        transition: all 350ms ease-in-out;
      }
      & > a {
        cursor: default;
      }
      @media (min-width: breakpoint(lg)) {
        &:hover {
          &::after {
            width: 0;
            border-bottom: 6px solid $color-red;
            border-top: none;
          }
          .cm-menu-main__submenu {
            opacity: 1;
          }
        }
      }
    }
    &--1 {
      position: relative;
      list-style-type: none;
      padding: 0 spacing(s);
      @media (min-width: breakpoint(lg)) {
        &:hover {
          & a {
            color: $color-red;
          }
        }
      }
      & > a {
        position: relative;
        display: block;
        padding: spacing(s) 0;
        width: 100%;
        @include font-12-bold();
        color: $color-text !important;
        transition: all 350ms;
        &::after {
          content: '';
          width: 0;
          height: 4px;
          background: $color-red;
          position: absolute;
          bottom: 0px;
          transition: all 350ms ease-in-out;
          left: 0;
          // transform: translateX(-50%);
        }
        &.is-active {
          color: $color-red;
        }
      }
      @media (min-width: breakpoint(lg)) {
        &:hover {
          & > a {
            &::after {
              width: 70%;
            }
          }
        }
      }
      &.cm-menu-main__list-item--active-trail {
        & > a {
          color: $color-red !important;
        }
        &::after {
          width: 60% !important;
        }
      }
    }
  }
  &__submenu {
    position: absolute;
    padding-left: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    top: calc(100% + 24px);
    opacity: 0;
    transition: all 250ms ease-in-out;
    background-color: $color-white;
    padding: spacing(m);
    border: 2px solid $color-red;
    box-shadow: 3px 6px 5px 0px #eaeaea;
  }

}
