@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.co-testo-esteso {
  $el: &;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(m);
  margin-bottom: spacing(xl);
  @media (min-width: breakpoint(lg)) {
    padding: spacing(xl) 0;
  }
  &__info {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-top: spacing(xl);
    text-align: center;
    @media (min-width: breakpoint(lg)) {
      padding: 0 spacing(xl);
      margin-top: spacing(l);
      text-align: left;
    }
    @media (min-width: breakpoint(xl)) {
      padding: 0 spacing(xxl);
      margin-top: spacing(xl);
    }
    &-logo {
      width: 50%;
      height: auto;
      margin: spacing(m) auto;
      margin-bottom: spacing(l);
      @media (min-width: breakpoint(lg)) {
        position: absolute;
        top: 0;
        right: spacing(xl);
        width: spacing(xxl) + spacing(xl);
      }
      @media (min-width: breakpoint(xl)) {
        right: spacing(xxl);
        width: spacing(xxl) + spacing(xxl);
      }
    }
    &-titolo {
      position: relative;
      padding: spacing(s) spacing(m);
      margin-bottom: spacing(l) + spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: spacing(s) (spacing(l) + spacing(m));
      }
      & h2 {
        position: relative;
        @include font-48-bold();
        color: $color-text;
        z-index: 3;
        margin: 0;
      }
      &-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-color: $color-red;
        z-index: 2;
      }
      &-bg {
        position: absolute;
        top: 50%;
        left: spacing(m) + spacing(s);
        transform: translateY(-50%);
        @include font-237-bold();
        color: $color-gray-light;
        z-index: 2;
        @media (min-width: breakpoint(lg)) {
          left: spacing(l) + spacing(m) + spacing(s);
        }
      }
    }
    &-sottotitolo {
      @include font-36-light();
      color: $color-text;
      line-height: 1.5;
      margin-bottom: spacing(l);
      text-transform: uppercase;
      padding: 0 spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: 0;
        padding-left: (spacing(l) + spacing(m));
        max-width: 70%;
      }
    }
    &-testo {
      @include font-18-reg();
      color: $color-text;
      padding: 0 spacing(m);
      line-height: 1.5;
      & strong{
        @include font-18-bold();
        line-height: 1.5;
      }
      & p {
        margin-bottom: spacing(m);
      }
      @media (min-width: breakpoint(lg)) {
        column-count: 2;
        column-gap: 80px;
        padding: 0;
        padding-left: (spacing(l) + spacing(m));
        width: 80%;
      }
    }
    &-link {
      display: inline-block;
      margin-top: spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding-left: (spacing(l) + spacing(m));
      }
    }
  }
  &__gallery {
    position: relative;
    width: 100%;
    margin-top: spacing(xl);
    padding: 0 spacing(m);
    @media (min-width: breakpoint(lg)) {
      margin-top: spacing(xxl);
      padding: 0;
      padding-left: spacing(xl);
    }
    & .owl-carousel {
      height: 35vh;
      @media (min-width: breakpoint(lg)) {
        height: 75vh;
      }
      & .owl-stage-outer {
        height: 100%;
        & .owl-stage {
          height: 100%;
          & .owl-item {
            width: auto !important;
            height: 100% !important;
            & > img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }
    // &-elem {
    //   position: relative;
    //   width: 100%;
    //   background-color: $color-white;
    //   overflow: hidden;
    //   &-logo {
    //     position: absolute;
    //     width: spacing(l) + spacing(m);
    //     top: 15vw;
    //     left: 4vw;
    //     z-index: 2;
    //     @media (min-width: breakpoint(lg)) {
    //       width: spacing(xl) + spacing(l);
    //       top: 2vw;
    //       left: 7vw;
    //     }
    //   }
    //   &-bg {
    //     position: absolute;
    //     width: 70%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     z-index: 1;
    //     @media (min-width: breakpoint(lg)) {
    //       left: 5vw;
    //     }
    //   }
    //   &-img {
    //     position: relative;
    //     padding-top: 20vw;
    //     right: 1.5vw;
    //     z-index: 2;
    //     @media (min-width: breakpoint(lg)) {
    //       padding-top: 0;
    //     }
    //     & > img {
    //       width: 125%;
    //     }
    //   }
    // }
  }
}