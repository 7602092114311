@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.co-storia {
  $el: &;
  position: relative;
  padding: 25vw spacing(m);
  padding-bottom: 12.5vw;
  margin-bottom: spacing(xl);
  @media (min-width: breakpoint(lg)) {
    padding: 10vw spacing(xl);
    padding-bottom: 5vw;
  }
  @media (min-width: breakpoint(xl)) {
    padding: 12vw (spacing(xl) + spacing(l));
    padding-bottom: 6vw;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 70%;
    background-color: $color-gray-light;
    z-index: 1;
  }
  &__intro {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    padding-bottom: spacing(xl);
    @media (min-width: breakpoint(lg)) {
      flex-direction: row;
      width: 75%;
    }
    @media (min-width: breakpoint(xl)) {
      width: 65%;
    }
    &-titolo {
        @include font-36-bold();
        line-height: 1.25;
        color: $color-text;
        margin-bottom: spacing(l);
        text-align: center;
        @media (min-width: breakpoint(lg)) {
          text-align: left;
          margin-bottom: 0;
          width: 50%;
        }
    }
    &-logo {
      text-align: center;
      @media (min-width: breakpoint(lg)) {
        width: 50%;
      }
      & > img {
        max-width: spacing(xxl) + spacing(xxl);
        margin: auto;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    @media (min-width: breakpoint(lg)) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  &__left {
    position: relative;
    display: flex;
    flex-direction: column;
    @media (min-width: breakpoint(lg)) {
      width: 60%;
    }
    &-line {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: $color-text;
      display: none;
      @media (min-width: breakpoint(lg)) {
        display: block;
      }
    }
    &-elem {
      position: relative;
      margin-bottom: spacing(l);
      padding-left: spacing(l);
      &::before {
        content: '';
        position: absolute;
        top: spacing(m);
        left: 0;
        width: spacing(m) + spacing(s);
        height: 4px;
        background-color: $color-red;
      }
      @media (min-width: breakpoint(lg)) {
        width: 50%;
        &::before {
          display: none;
        }
      }
      &-titolo {
        @include font-48-light();
        margin-bottom: spacing(m);
      }
      &-testo {
        @include font-18-reg();
        line-height: 1.35;
        margin-bottom: spacing(m);
      }
      &-logo {
        height: spacing(xl);
      }
      @media (min-width: breakpoint(lg)) {
        &:nth-child(even) {
          margin-left: auto;
          padding-left: spacing(l);
          #{$el}__left-elem-titolo {
            &::before {
              content: '';
              position: absolute;
              top: spacing(m) + spacing(s);
              left: - spacing(l);
              width: spacing(m) + spacing(s);
              height: 4px;
              background-color: $color-red;
            }
          }
        }
        &:nth-child(odd) {
          margin-right: auto;
          padding-right: spacing(l);
          text-align: right;
          #{$el}__left-elem-titolo {
            &::before {
              content: '';
              position: absolute;
              top: spacing(m) + spacing(s);
              right: - spacing(l);
              width: spacing(m) + spacing(s);
              height: 4px;
              background-color: $color-red;
            }
          }
          #{$el}__left-elem-logo {
            margin-left: auto;
          }
        }
      }
    }
  }
  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    @media (min-width: breakpoint(lg)) {
      padding-left: spacing(l);
      width: 40%;
    }
    &-elem {
      margin-bottom: spacing(l);
      @media (min-width: breakpoint(lg)) {
        width: 85%;
        &:nth-child(even) {
          margin-right: auto;
        }
        &:nth-child(odd) {
          margin-left: auto;
        }
      }
    }
  }
}