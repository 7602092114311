@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cp-home {
  $el: &;
  position: relative;
  &__intro-block {
    padding-top: 70px;
    margin-bottom: 8vw;
    @media (min-width: breakpoint(lg)) {
      margin-bottom: 10vw;
    }
    @media (min-width: breakpoint(xl)) {
      padding-top: 72px;
    }
  }
  
}
