@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-icone {
  $el: &;
  position: relative;
  padding: spacing(l) spacing(m);
  margin: spacing(xl) auto;
  @media (min-width: breakpoint(lg)) {
    padding: 0 spacing(xl);
  }
  @media (min-width: breakpoint(xl)) {
    padding: 0 spacing(xxl);
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: breakpoint(lg)) {
      flex-direction: row;
    }
  }
  &__info {
    position: relative;
    width: 100%;
    &-titolo {
      position: relative;
      padding: spacing(s) spacing(m);
      margin-bottom: spacing(l) + spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: spacing(s) (spacing(l) + spacing(m));
      }
      & h2 {
        position: relative;
        @include font-48-bold();
        color: $color-text;
        z-index: 3;
        margin: 0;
      }
      &-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-color: $color-red;
        z-index: 2;
      }
      &-bg {
        position: absolute;
        top: 50%;
        left: spacing(m) + spacing(s);
        transform: translateY(-50%);
        @include font-237-bold();
        color: $color-gray-light;
        z-index: 2;
        @media (min-width: breakpoint(lg)) {
          left: spacing(l) + spacing(m) + spacing(s);
        }
      }
    }
    &-sottotitolo {
      @include font-36-light();
      color: $color-text;
      line-height: 1.5;
      margin-bottom: spacing(m);
      text-transform: uppercase;
      padding: 0 spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: 0;
        padding-left: (spacing(l) + spacing(m));
      }
    }
    &-testo {
      @include font-18-reg();
      color: $color-text;
      padding: 0 spacing(m);
      line-height: 1.5;
      & strong{
        @include font-18-bold();
        line-height: 1.5;
      }
      & p {
        margin-bottom: spacing(m);
      }
      @media (min-width: breakpoint(lg)) {
        padding: 0;
        padding-left: (spacing(l) + spacing(m));
        width: 80%;
      }
    }
    
  }
  &__list {
    position: relative;
    width: 100%;
    margin-top: spacing(l) + spacing(m);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: breakpoint(lg)) {
      flex-direction: row;
      margin-top: 0;
      width: 65%;
      margin: 0 auto;
    }
    &-elem {
      position: relative;
      padding: spacing(l);
      text-align: center;
      @media (min-width: breakpoint(lg)) {
        padding: spacing(l) spacing(xl);
      }
      &-logo {
        width: spacing(xxl);
        height: spacing(xxl);
        margin: 0 auto;
        margin-bottom: spacing(m);
      }
      &-titolo {
        position: relative;
        @include font-36-light();
        line-height: 1.25;
        color: $color-text;
        margin-bottom: spacing(m);
        text-align: center;
      }
      &-sottotitolo {
        @include font-36-light();
        color: $color-text;
        margin-bottom: spacing(m);
        text-transform: uppercase;
        text-align: center;
      }
      &-testo {
        @include font-18-reg();
        color: $color-text;
        line-height: 1.35;
        & strong{
          @include font-18-bold();
        }
      }
    }
    & .js-gallery-servizi-pag {
      bottom: -spacing(l);
      width: 100%;
      background-color: #e79595;
      height: 2px;
      & .swiper-scrollbar-drag {
        // width: spacing(xxl) !important;
        height: 6px;
        top: -2px !important;
        border-radius: 0;
        background-color: $color-red;
      }
    }
  }
}