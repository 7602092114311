@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-dealer {
  $el: &;
  position: relative;
  padding: spacing(l) 0;
  margin: spacing(xl) auto;
  @media (min-width: breakpoint(lg)) {
    margin: spacing(xxl) auto;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__info {
    position: relative;
    width: 100%;
    text-align: center;
    &-titolo {
      position: relative;
      padding: spacing(s) (spacing(l) + spacing(m));
      margin-bottom: spacing(l) + spacing(m);
      & h2 {
        position: relative;
        @include font-48-bold();
        color: $color-text;
        z-index: 3;
        margin: 0;
      }
    }
    &-sottotitolo {
      @include font-36-light();
      color: $color-text;
      margin-bottom: spacing(m);
      text-transform: uppercase;
      padding-left: (spacing(l) + spacing(m));
    }
    &-testo {
      @include font-18-reg();
      color: $color-text;
      padding-left: (spacing(l) + spacing(m));
      & strong{
        @include font-18-bold();
      }
      @media (min-width: breakpoint(lg)) {
        width: 80%;
      }
    }
    
  }
  &__list {
    padding: spacing(l) spacing(m);
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (min-width: breakpoint(lg)) {
      padding: spacing(xl) spacing(xxl);
      flex-direction: row;
      flex-flow: nowrap;
      justify-content: space-between;
    }
    &-elem {
      position: relative;
      text-align: center;
      background-color: $color-white-transparent;
      transition: all 300ms ease-in-out;
      margin: spacing(m);
      width: 35vw;
      height: 35vw;
      @media (min-width: breakpoint(lg)) {
        width: 15vw;
        height: 15vw;
      }
      &-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 65%;
      }
      &-link {
        width: spacing(l);
        height: spacing(l);
        position: absolute;
        bottom: spacing(s);
        right: spacing(s);
        opacity: 0;
      }
      &:hover {
        background-color: $color-white;
        #{$el}__list-elem-link {
          opacity: 1;
        }
      }
    }
  }
}