@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.co-footer {
    $el: &;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: spacing(l) spacing(m);
    z-index: zindex(l);
    background-color: $color-black;
    @media (min-width: breakpoint(lg)) {
        padding: spacing(xl);
    }
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: breakpoint(lg)) {
            flex-direction: row;
        }
        & > div {
            margin-bottom: spacing(l);
            @media (min-width: breakpoint(lg)) {
                width: calc(100% / 3);
            }
        }
        &-left {
            display: flex;
            align-items: center;
        }
        &-center {
            text-align: center;
            & p {
                text-align: center !important;
            }
            & a {
                margin-left: spacing(s);
            }
        }
        &-right {
            position: relative;
        }
    }
    &__logo {
        margin-bottom: spacing(l);
    }
    &__text {
        position: relative;
        & p {
            display: block;
            @include font-18-reg();
            line-height: 1.5;
            margin-bottom: spacing(l);
            text-align: center;
            color: $color-white;
            @media (min-width: breakpoint(lg)) {
                text-align: left;
            }
        }
        & h3 {
            display: block;
            @include font-18-bold();
            line-height: 1.5;
            margin-bottom: spacing(s);
            text-align: center;
            color: $color-white;
            @media (min-width: breakpoint(lg)) {
                text-align: left;
            }
        }
        & a {
            position: relative;
            color: $color-white;
            transition: all 250ms ease-in-out;
            &:hover {
                color: $color-red;
            }
        }
        &--right {
            // & h3 {
                //     text-align: right !important;
                // }
                // & p {
                    //     text-align: right !important;
                    // }
            @media (min-width: breakpoint(lg)) {
                margin-left: 12vw;
                & h3 {
                    text-align: right !important;
                }
                & p {
                    text-align: right !important;
                }
            }
        }
    }
    // @at-root .path-frontpage {
    //     #{$el} {
    //         display: none;
    //     }
    // }
}