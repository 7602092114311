@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.ca-advanced-button {
  $el: &;
  display: inline-block;
  position: relative;
  border: none;
  padding: spacing(m) spacing(l);
  padding-right: 65px;
  border-radius: 0;
  transition: all 350ms ease-in-out;
  @include font-24-bold();
  overflow: hidden;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    width: 0;
    height: 100%;
    background-color: $color-red;
    transition: all 300ms ease-in-out;
  }
  &::after {
    content: '\2192';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;
    width: spacing(l);
    height: spacing(l);
    // background-image: url('btn.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center center;
    transition: all 350ms ease-in-out;
  }
  & > span {
    position: relative;
    z-index: 2;
    color: $color-red !important;
  }
  &:hover {
    &::after {
      color: $color-white;
    }
    &::before {
      width: 100%;
    }
    & > span {
      color: $color-white !important;
    }
  }
}
