@use '../../settings/typography' as *;
// font 237
@mixin font-237-bold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: 4.15rem;
  @media (min-width: breakpoint(lg)) {
    font-size: $font-size-xxl;
  }
}
// font 48
@mixin font-48-bold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-l;
  @media (min-width: breakpoint(lg)) {
    font-size: $font-size-xl;
  }
}
@mixin font-48-light() {
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-l;
  @media (min-width: breakpoint(lg)) {
    font-size: $font-size-xl;
  }
}
// font 36
@mixin font-36-bold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-m;
  @media (min-width: breakpoint(lg)) {
    font-size: $font-size-l;
  }
}
@mixin font-36-light() {
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-m;
  @media (min-width: breakpoint(lg)) {
    font-size: $font-size-l;
  }
}
// font 24
@mixin font-24-bold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: $line-height-xl;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-m;
}
@mixin font-24-reg() {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  line-height: $line-height-xl;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-m;
}
// font 18
@mixin font-18-bold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-s;
}
@mixin font-18-semibold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-semibold;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-s;
}
@mixin font-18-reg() {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-s;
}
// font 12
@mixin font-12-bold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-m;
  font-optical-sizing: auto;
  font-size: $font-size-xs;
}