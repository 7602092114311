@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.ca-general-button {
  $el: &;
  display: inline-block;
  position: relative;
  background-color: $color-red;
  border: none;
  border-radius: 50px;
  border: 2px solid $color-red;
  padding: (spacing(s) + spacing(xs)) spacing(l);
  color: $color-white !important;
  transition: all 350ms ease-in-out;
  @include font-18-bold();
  overflow: hidden;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    background: $color-white;
    color: $color-red !important;
  }
  &--neg {
    color: $color-red !important;
    background-color: $color-white;
    &:hover {
      background: $color-red;
      color: $color-white !important;
    }
  }
}
