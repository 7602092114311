@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cp-advanced-page {
  $el: &;
  position: relative;
  &__intro-block {
    margin-bottom: spacing(xl);
    @media (min-width: breakpoint(lg)) {
      margin-bottom: spacing(xxl);
    }
  }
  &__intro {
    position: relative;
    width: 100%;
    height: 65vh;
    margin-top: 70px;
    &-heading {
      position: absolute;
      top: 50%;
      left: 4%;
      transform: translateY(-50%);
      z-index: 2;
      width: 96%;
      @media (min-width: breakpoint(lg)) {
        left: 15%;
        width: 45%;
      }
      @media (min-width: breakpoint(xl)) {
        width: 30%;
      }
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 1;
    }
    &-titolo {
      position: relative;
      @include font-48-bold();
      color: $color-white;
    }
    &-sottotitolo {
      @include font-36-light();
      color: $color-white;
      line-height: 1.5;
      margin-bottom: spacing(m);
      text-transform: uppercase;
    }
  }
  &__content {
    position: relative;
    & > iframe {
      width: 100%;
    }
    &--text {
      @include font-36-light();
      color: $color-text;
      line-height: 1.5;
      margin-bottom: spacing(l);
      padding: 0 spacing(m);
      @media (min-width: breakpoint(lg)) {
        width: 70%;
        margin: spacing(xl) auto;
      }
    }
  }
  &__content-news {
    position: relative;
    border-top: 8px solid $color-red;
    padding: spacing(xl) spacing(m);
    text-align: center;
    @media (min-width: breakpoint(lg)) {
      padding: spacing(xl) spacing(m);
    }
    &-titolo {
      display: inline-block;
      position: relative;
      padding: spacing(s) spacing(m);
      margin-bottom: spacing(l) + spacing(m);
      @media (min-width: breakpoint(lg)) {
        padding: spacing(s) (spacing(l) + spacing(m));
      }
      & h2 {
        position: relative;
        @include font-48-bold();
        color: $color-text;
        z-index: 3;
        margin: 0;
      }
      &-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-color: $color-red;
        z-index: 2;
      }
      &-bg {
        position: absolute;
        top: 50%;
        left: spacing(m) + spacing(s);
        transform: translateY(-50%);
        @include font-237-bold();
        color: $color-gray-light;
        z-index: 2;
        @media (min-width: breakpoint(lg)) {
          left: spacing(l) + spacing(m) + spacing(s);
        }
      }
    }
    &-link {
      text-align: center;
      margin: spacing(l) auto;
    }
    &-list {
      display: flex;
      flex-direction: column;
      @media (min-width: breakpoint(lg)) {
        align-items: flex-start;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}
