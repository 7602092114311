@use 'colors' as *;
@use 'typography' as *;
@use 'spaces' as *;
@use 'breakpoints' as *;
@use 'z-index' as *;
@use '../tools/typography/base-font-primary' as *;

html {
  scroll-behavior: smooth;
}
body {
  font-family: $font-family-primary;
  margin: 0;
  padding: 0;
  line-height: 1;
  background-color: $color-white;
  color: $color-text;
  // overflow: hidden;
}

.inview-elem {
  position: relative;
  opacity: 0;
  top: -15px;
  transition: all 850ms ease-in-out;
  &--slow {
    transition: all 1250ms ease-in-out;
  }
}
.inview-elem.is-inview-elem {
  opacity: 1;
  top: 0;
}

.inview-elem-opacity {
  opacity: 0;
  transition: all 850ms ease-in-out;
}
.inview-elem-opacity.is-inview-elem-opacity {
  opacity: 1;
}

.placeholder {
  cursor: auto;
  background-color: $color-transparent;
  min-height: auto;
  opacity: 1;
  font-style: normal;
  vertical-align: baseline;
}

.cm-status-message {
  width: 100%;
  padding: spacing(xl) spacing(m);
  border-radius: spacing(m);
  background-color: $color-white;
  box-shadow: 2.02px 2.02px 8.08px 0px #00000040;
  @include font-48-bold();
  color: $color-text;
  & .placeholder {
    & a {
      @include font-48-bold();
      color: $color-red;
    }
  }
}

p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
  color: $color-red;
}
.visually-hidden {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}

.gen-prenota {
  position: absolute;
  bottom: -51px;
  right: 0;
  padding: spacing(m);
  background-color: $color-red;
  color: $color-white;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  z-index: 10;
  @include font-18-bold();
  &:hover {
    background-color: $color-black;
  }
  &--black {
    background-color: $color-black;
    &:hover {
      background-color: $color-red;
    }
  }
  @media (min-width: breakpoint(lg)) {
    padding: spacing(m) spacing(xl);
  }
}
@at-root .path-frontpage {
    .gen-prenota {
      background-color: $color-black;
      &:hover {
        background-color: $color-red;
      }
    }
}



.swiper-pagination {
  .swiper-pagination-bullet {
    background-color: $color-gray-dark;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $color-red;
  }
  &.js-gallery-intro-pag {
    bottom: -spacing(l);
    left: -4%;
  }
}