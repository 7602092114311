@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-social-box {
  $el: &;
  position: relative;
  display: flex;
  align-items: center;
  &__item {
    position: relative;
    margin: 0 spacing(s);
    transition: all 350ms ease-in-out;
    &:hover {
      transform: scale(1.12);
    }
    &-link {
      display: block;
      width: spacing(m) + spacing(s);
      height: spacing(m) + spacing(s);
      & > img {
        width: 100%;
        height: auto;
      }
    }
  }
  &--footer {
    justify-content: center;
    margin-bottom: spacing(l);
    #{$el}__item {
      margin: 0 spacing(xs);
      &-link {
        width: spacing(l);
        height: spacing(l);
      }
    }
  }
}
