@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cm-news {
  $el: &;
  position: relative;
  &-teaser {
    position: relative;
    width: 100%;
    @media (min-width: breakpoint(lg)) {
      width: calc(100% / 3);
    }
    &__inner {
      display: flex;
      flex-direction: column;
      padding: 0 spacing(m);
      margin-bottom: spacing(l);
      @media (min-width: breakpoint(lg)) {
        padding: 0 spacing(xl);
      }
    }
    &__image {
      position: relative;
      margin-bottom: spacing(m);
    }
    &__titolo {
      position: relative;
      margin-bottom: spacing(m);
      @include font-24-bold();
      line-height: 1.25;
      color: $color-text;
      text-align: left;
    }
    &__testo {
      position: relative;
      margin-bottom: spacing(m);
      @include font-18-reg();
      color: $color-text;
      text-align: left;
    }
    &__link {
      position: relative;
      @include font-18-bold();
      color: $color-red;
      text-align: left;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}