@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.co-header {
  $el: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: zindex(xxl);
  padding: spacing(m) spacing(l);
  transition: all 350ms ease-in-out;
  background-color: $color-white;
  box-shadow: 3px 3px 5px 0px #cecece;
  // @media (min-width: breakpoint(xl)) {
  //   padding: spacing(l);
  // }
  // border-bottom: 2px solid $color-red;
  &__toggler-box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: spacing(m);
    z-index: zindex(l);
    @media (min-width: breakpoint(xl)) {
      display: none;
    }
  }
  &__inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @media (min-width: breakpoint(xl)) {
        width: auto;
        display: block;
      }
    }
  }
  &__menu-box {
    margin: 0 auto;
    display: none;
    @media (min-width: breakpoint(xl)) {
      display: block;
    }
  }
  &__logo {
    position: relative;
    & a {
      display: block;
      & img {
        min-width: spacing(l);
        max-width: spacing(xl);
        @media (min-width: breakpoint(lg)) {
          min-width: spacing(l);
          max-width: spacing(xl);
        }
      }
    }
  }
}