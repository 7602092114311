@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.co-intro-advanced-page {
  $el: &;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(m);
  margin-bottom: spacing(xl);
  @media (min-width: breakpoint(lg)) {
    padding: 0;
    flex-direction: row;
    align-items: stretch;
  }
  &__info {
    position: relative;
    width: 100%;
    z-index: 1;
    order: 2;
    margin-top: spacing(xl);
    text-align: center;
    @media (min-width: breakpoint(lg)) {
      order: 1;
      padding: 0 spacing(xl);
      width: 50%;
      margin-top: spacing(l);
      text-align: left;
    }
    @media (min-width: breakpoint(xl)) {
      padding: 0 spacing(xxl);
      margin-top: spacing(xl);
    }
    &-logo {
      width: 50%;
      height: auto;
      margin: spacing(m) auto;
      margin-bottom: spacing(l);
      @media (min-width: breakpoint(lg)) {
        margin: 0;
        margin-bottom: spacing(xl);
        width: 55%;
      }
    }
    &-titolo {
      @include font-48-bold();
      color: $color-text;
      margin-bottom: spacing(l);
    }
    &-sottotitolo {
      @include font-36-light();
      color: $color-text;
      margin-bottom: spacing(m);
      text-transform: uppercase;
    }
    &-testo {
      @include font-24-reg();
      color: $color-text;
      margin-bottom: spacing(m);
      & strong{
        @include font-24-bold();
      }
      @media (min-width: breakpoint(lg)) {
        width: 80%;
      }
    }
  }
  &__gallery {
    position: relative;
    width: 100%;
    order: 1;
    @media (min-width: breakpoint(lg)) {
      order: 2;
      width: 52%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }
    &-bg {
      position: absolute;
      width: 70%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: $color-red;
      @media (min-width: breakpoint(lg)) {
        // left: 5vw;
      }
    }
    &-inner {
      
    }
    &-elem {
      position: relative;
      width: 100%;
      background-color: $color-transparent;
      overflow: hidden;
      z-index: 5;
      margin-right: spacing(xl);
      &-logo {
        position: absolute;
        width: spacing(l) + spacing(m);
        top: 15vw;
        left: 4vw;
        z-index: 2;
        opacity: 0;
        @media (min-width: breakpoint(lg)) {
          width: spacing(xl) + spacing(l);
          top: 2vw;
          left: 2vw;
        }
      }
      // &-bg {
      //   position: absolute;
      //   width: 70%;
      //   height: 100%;
      //   top: 0;
      //   left: 0;
      //   z-index: 1;
      //   @media (min-width: breakpoint(lg)) {
      //     left: 5vw;
      //   }
      // }
      &-img {
        position: relative;
        padding-top: 20vw;
        right: 1.5vw;
        z-index: 2;
        @media (min-width: breakpoint(lg)) {
          padding-top: 0;
        }
        & > img {
          width: 125%;
        }
      }
    }
    & .swiper {
      z-index: 5;
      & .swiper-slide {
        overflow: visible;
      }
    }
  }
}