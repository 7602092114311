@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.cp-user {
  $el: &;
  position: relative;
  display: block;
  width: 100%;
  &__inner {
    position: relative;
    width: 100%;
    height: 45vh;
    & .user-login-form {
      padding: 15vh spacing(xxl);
    }
  }
  &__actions {
    text-align: center;
    margin: spacing(l) auto;
    & a {
      color: $color-red;
      @include font-24-bold();
      text-decoration: none;
      position: relative;
    }
    & h2 {
      @include font-24-bold();
      color: $color-text;
      margin-bottom: spacing(m);
    }
    & p {
      @include font-24-bold();
      text-decoration: none;
      position: relative;
      margin-bottom: spacing(l);
    }
  }
}
